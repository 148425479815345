<template>
    <b-container>

        <h2>Damage and Vandalism</h2>

        <p>Residents may not deliberately or through negligence deface or destroy Island Condos property or property
            belonging to another resident. You are expected to reimburse Student Housing for any damage that you cause. The
            cost of replacement or repair will be deducted from your security deposit. If the amount exceeds the security
            deposit, the additional costs will be billed.</p>

        <h2>Communal Damages</h2>

        <p>In the event of property damage, the cost of repair or replacement will be deducted from the security deposit of
            the responsible party. If the cost exceeds the security deposit, the additional cost will be billed to the
            tenant.</p>

        <p>In cases where it is not possible to determine who is responsible for damage, the entire unit or building will be
            charged; and the price of replacement or repair will be deducted equally from the security deposits of all
            tenants. If the cost exceeds the security deposits, the additional cost will be shared equally.</p>

        <p>If your security deposit is used to cover damages while you are still living in student housing, you will be
            required to pay an additional security deposit to offset the cost of any additional damages that may be incurred.
            If there are no further damages or violations, the deposit will be returned upon move out as usual.</p>

        <h2>Check Out</h2>

        <p>Residents are expected to notify Student Housing of their intentions to vacate their room <b>14 business days</b>
            prior to moving out <b><i><u>even if it is the end of your contract.</u></i></b> <b><i><u>We need this time to
                prepare your security deposit so, if you do not give notice of move out, return of your deposit will be delayed
                and you will not receive it that day.</u></i></b></p>

        <p>Rooms should be left in the same condition they were received: free of personal items, trash, and the
            refrigerator must be emptied of your food items. Linens should be left on the bed and all windows should be closed
            and locked.</p>

        <p>Your room will be inspected by a member of staff before your security deposit is returned. Any damage found will
            be deducted from your security deposit. If the damage exceeds the security deposit, you will be billed for the
            additional cost.</p>

        <p>Keys and fobs MUST be returned to student housing upon check out. If they are not returned, you will be charged
            $75 for each missing item (i.e., missing key $75, missing key and fob $150).</p>

        <h2><b><i><u>Health and Safety</u></i></b></h2>

        <p>The security office is located to the left of the main entrance of Island Condos and is staffed 24 hours a day.
            After 5 pm, the management staff is replaced with security guards.</p>

        <p>Locking rooms and securing belongings are the sole responsibility of the tenant. Island Condos accepts no
            responsibility for lost or stolen property.</p>

        <h2>Entry and Exit</h2>

        <p>All entry and exit should be through the Island Condos main gate next to the security office. All doors facing
            Girard Street must remain locked at all times and entry/exit is strictly prohibited. Using the front doors without
            written permission from Student Housing will result in:</p>

        <p>First infraction: written warning.</p>
        <p>Second infraction: forfeiture of security deposit and probation</p>
        <p>Third infraction: eviction.</p>

        <h2>Alcohol</h2>

        <p>The possession, use or intent to distribute alcoholic beverages and/or dangerous substances of any kind (even
            legal ones like marijuana and mushrooms) is strictly prohibited even if you are of legal age (21 years old) to
            consume them. If you are found with alcohol and/or controlled substances for personal use:</p>

        <p><u>International Students</u> will forfeit their security deposit, be permanently banned from Island Condos, and
            have their I-20’s terminated. If you have prepaid, your contract with Island Condos will continue through the
            original housing dates and your money will not be returned.</p>

        <p><u>Domestic Students</u> will forfeit their security deposit and be permanently banned from Island Condos. Your
            contract with Island Condos will continue through the original housing dates and any pre-payments will not be
            returned.</p>

        <h2>Controlled Substances</h2>

        <p>If distribution of controlled substances is suspected, law enforcement may be called, and criminal charges may be
            filed.</p>

        <p><i>If you are found with drug-related paraphernalia:</i></p>

        <p>First infraction: written warning</p>
        <p>Second infraction: forfeiture of security deposit and removal from Student Housing.</p>

        <p>Please note: the misuse of prescription or over-the-counter medication, including, but not limited to, sales and
            distribution is prohibited. If a student is suspected of distributing prescription medication, law enforcement may
            be notified.</p>

        <b-col>
            <ValidationObserver ref="formModalValidate">
                <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                    <div class="mr-3">
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="form.status"
                            name="checkbox-1"
                            value="accepted"
                            :unchecked-value="null"
                        >
                            I have read, understood, and accept
                        </b-form-checkbox>
                        <b-form-invalid-feedback v-if="errors[0]"
                                                 v-html="errors[0]">

                        </b-form-invalid-feedback>
                    </div>
                </ValidationProvider>
                <div class="d-flex justify-content-center  align-center mt-3 mb-3">
                    <b-button @click="next" variant="primary" class="mr-3">{{
                            $t('next')
                        }}
                    </b-button>
                </div>
            </ValidationObserver>
        </b-col>


    </b-container>
</template>
<script>
export default {

    data() {
        return {
            form: {
                status:null,
            }
        }
    },
    methods:{
        async next() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.$emit('next', 1)
            }

        },
    }
}
</script>
<style>
@import ".././Style/Style.css";
</style>
