<template>
    <div>
        <p>After clicking the Approve button, your reservation request will be created. Once your request is approved, you will receive an email with an agreement form and a payment link. To complete your reservation, you will need to sign the agreement form and pay a security deposit of ${{securityDepositFee}}.</p>
        <div class="d-flex justify-content-center mt-3">
            <b-button @click="ApprovedButton()">
                {{ $t('approve') }}
            </b-button>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        securityDepositFee:{
            type:Number
        }
    },
    data(){
        return{

        }
    },
    methods:{
        ApprovedButton(){
            this.$emit('approvedButtonClick',true)
        }
    }
}
</script>
