<template>
    <div>
        <b-row class="mt-3" v-if="showPage">
            <b-col cols="12" class="d-flex justify-content-center align-item-center mb-5">
                <div class="logo-wrapper ">
                    <img src="../../../assets/img/island-candos-logo.png" width="100%" />
                </div>
            </b-col>
            <b-col cols="12" class="d-flex justify-content-center align-item-center">
            </b-col>
            <b-col>
                <template>
                    <page1 v-if="currentPage==1" @next="next($event)" @back="next($event)"/>
                </template>
                <template>
                    <page2 v-if="currentPage==2" @next="next($event)" @back="next($event)"/>
                </template>
                <template>
                    <page3 v-if="currentPage==3" @next="next($event)" @back="next($event)"/>
                </template>
                <template>
                    <page4 v-if="currentPage==4" @next="next($event)" @back="next($event)"/>
                </template>
                <template>
                    <page5 v-if="currentPage==5" @next="next($event)" @back="next($event)"/>
                </template>
                <template>
                    <page6 v-if="currentPage==6" @next="next($event)" @back="next($event)"/>
                </template>
                <template>
                    <page7 v-if="currentPage==7" @next="next($event)" @back="next($event)"/>
                </template>
                <template>
                    <page8 v-if="currentPage==8" @next="next($event)" @back="next($event)"/>
                </template>
            </b-col>
        </b-row>
        <div class="wrapper" v-else>
            <div class="message-box" v-if="message">
                <b-alert show variant="danger">
                    <h4 class="alert-heading">unsuccessful!</h4>
                    <p>{{$t('api.' + message)}}</p>
                </b-alert>
            </div>

        </div>

        <CommonModal size="lg" ref="housingAgreementForm" :closeBtn="false" :noCloseOnBackDrop="true">
            <template v-slot:CommonModalTitle>
                <div>{{ $t("housing_agreement_form").toUpperCase() }}</div>
            </template>
            <template v-slot:CommonModalContent>

                <AgreementForm :studentType="type" v-if="formProcess === 'agreement-form'" />
            </template>
        </CommonModal>

    </div>

</template>
<script>

import HousingReservationAgreementService from "@/services/HousingReservationAgreementService";
import AgreementForm from "@/modules/housingAgreementForm/pages/AgreementForm.vue";
import Page3 from "@/modules/housingAgreementForm/pagess/page3.vue";
import Page4 from "@/modules/housingAgreementForm/pagess/page4.vue";
import Page6 from "@/modules/housingAgreementForm/pagess/page6.vue";
import Page5 from "@/modules/housingAgreementForm/pagess/page5.vue";
import Page1 from "@/modules/housingAgreementForm/pagess/page1.vue";
import Page2 from "@/modules/housingAgreementForm/pagess/page2.vue";
import Page7 from "@/modules/housingAgreementForm/pagess/page7.vue";
import Page8 from "@/modules/housingAgreementForm/pagess/page8.vue";

export default {
  components: {
      Page8, Page7, Page2, Page1, Page5, Page6, Page4, Page3,
        AgreementForm,


  },
  metaInfo() {
    return {
      title: this.$t('housing_agreement_form')
    }
  },
  data() {
    return {
        message:null,
        formProcess: null,
        currentPage: 1,
        type: null,
        showModal: false,
        showPage: false,
    }
  },
    watch:{
      showModal(newVal) {
          if (newVal) {
              this.openModal()
           }
          }
    },
  methods: {
      next(event) {

          if (this.currentPage < 9) {
              if (this.currentPage < 8) {
                  this.currentPage += event;
                  window.scrollTo({ top: 0, behavior: 'smooth' });
              } else {
                  this.showModal = true

              }
          }
      },
     isReservation(){
       HousingReservationAgreementService.checkToken(this.$route.params.token)
           .then(res=>{
               this.type=res.data.data.type;
             if (res.data.success){
                 this.showPage=true

             }
           }).catch(err=>{
             this.message=err.data.message
             this.showPage=false
             this.showErrors(err)
       })
     },
    openModal(){
      this.formProcess = 'agreement-form';
      this.$refs.housingAgreementForm.$refs.commonModal.show()
    }
  },
  created() {
    this.isReservation()
  },
};
</script>
<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  border: 1px solid #ddd;
  position: relative;
}


.message-box {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>


