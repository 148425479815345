
const routes = [
    {
        path: "/housing/tenant/login",
        component: () => import("./../pages/Index"),
        meta: {
             isNotAuthenticated: false
        }
    },
    // {
    //     path: "/logout",
    //     component: () => {
    //         store.dispatch("auth/logout");
    //     }
    // },
];

export default routes;
