<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('housing_reservations')"
                        :isFilter="false"
                        :isColumns="false"
                />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile
                    :title="$t('housing_reservations')"
                    :isFilter="false"
                    :isColumns="false"
                />
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                              :exportExcel="false" @exportExcel="exportExcel">
                <b-row>
                    <b-col cols="6" md="6">
                        <b-form-group :label="$t('first_name')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.filter_by_name"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" md="6">
                        <b-form-group :label="$t('last_name')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.filter_by_surname"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" md="6">
                        <b-form-group :label="$t('tenant_type')">
                            <parameter-selectbox
                                :code="'housing_tenant_types'"
                                v-model="datatable.queryParams.filter.tenant_type"
                            ></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" md="6">
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox
                                :code="'housing_reservation_statuses'"
                                v-model="datatable.queryParams.filter.status"
                            ></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" md="6">
                        <b-form-group :label="$t('start_date')+' / '+$t('end_date')">
                            <v-date-picker v-model="datatable.queryParams.filter.date"
                                           is-range
                                           :locale="'en'"
                                           :model-config="{type: 'string', mask: 'MM/DD/YYYY'}"
                            >
                                <template v-slot="{ inputValue, inputEvents }">
                                    <div class="border rounded-sm p-1 p-md-3 d-flex align-items-center">
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 mr-1">
                                                <input :value="inputValue.start"
                                                       v-on="inputEvents.start"
                                                       :placeholder="$t('start_date')"
                                                       class="form-control"
                                                />
                                            </b-form-group>
                                        </div>
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 ml-1">
                                                <input :value="inputValue.end"
                                                       v-on="inputEvents.end"
                                                       :placeholder="$t('end_date')"
                                                       class="form-control"
                                                />
                                            </b-form-group>
                                        </div>
                                    </div>
                                </template>
                            </v-date-picker>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            />
            <CommonModal ref="successModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('reservation_form_detail').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form
                        v-if="formProcess == 'reservationModal'"
                        :reservation-data="selectedReservation"
                        :filters="datatable.queryParams.filter"
                        @successUpdate="successfully"
                    />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Component
import Datatable from '@/components/datatable/Datatable'
import DatatableFilter from '@/components/datatable/DatatableFilter'
import CommonModal from '@/components/elements/CommonModal'

// Services
import HousingReservationsService from '@/services/HousingReservationsService'

// Pages
import ShowForm from '@/modules/housingReservations/pages/ShowForm'


// Other
import qs from 'qs'

export default {
    props: {
        rsRefresh: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Datatable,
        DatatableFilter,
        CommonModal,
        ShowForm
    },
    metaInfo() {
        return {
            title: this.$t('housing_reservations')
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('detail'),
                                class: 'ri-calendar-check-line',
                                hidden: false,
                                permissions: "housingreservation_show",
                                callback: (row) => {
                                    this.updateFormShow(row);
                                }
                            },
                            {
                                text: this.$t('payment_info'),
                                class: 'ri-money-dollar-circle-line',
                                permission: 'customer_customerpayments',
                                callback: (row) => {
                                    if(row.student_program_id){
                                        window.open('/customers/payments/student/' + row.student_program_id,'_blank');
                                    }
                                    else if(row.tenant_id){
                                        window.open('/customers/payments/tenant/' + row.tenant_id,'_blank');
                                    }
                                }
                            },
                        ]
                    },
                    {
                        label: this.$t('name'),
                        field: 'name',
                        sortable: false
                    },
                    {
                        label: this.$t('surname'),
                        field: 'surname',
                        sortable: false
                    },
                    {
                        label: this.$t('check_in_date'),
                        field: 'check_in_date',
                        sortable: false
                    },
                    {
                        label: this.$t('check_out_date'),
                        field: 'check_out_date',
                        sortable: false
                    },
                    {
                        label: this.$t('total_days'),
                        field: 'total_days',
                        sortable: false
                    },
                    {
                        label: this.$t('monthly_fee'),
                        field: 'fee',
                        sortable: false,
                        formatFn: (row) => {
                            return this.currencyFormat(row)
                        }
                    },
                    {
                        label: this.$t('total_amount'),
                        field: 'total_amount',
                        sortable: false,
                        formatFn: (row) => {
                            return this.currencyFormat(row)
                        }
                    },
                    {
                        label: this.$t('paid_amount'),
                        field: 'total_paid_amount',
                        sortable: false,
                        formatFn: (row) => {
                            return this.currencyFormat(row)
                        }
                    },
                    {
                        label: this.$t('balance'),
                        field: 'balance',
                        sortable: false,
                        formatFn: (row) => {
                            return this.currencyFormat(row)
                        }
                    },
                    {
                        label: this.$t('type'),
                        field: 'type_text',
                        sortable: false
                    },
                    {
                        label: this.$t('status'),
                        field: 'status_name',
                        sortable: false
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                        check_in_date: null,
                        check_out_date: null,
                    },
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },
            formLoading: false,
            formId: null,
            formProcess: null,
            date: {
                start: null,
                end: null
            },
            selectedReservation: null
        }
    },
    watch: {
        rsRefresh: {
            handler: function (val) {
                if (val) {
                    this.getRows()
                }
            }
        }
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {
                check_in_date: null,
                check_out_date: null,
                filter_by_name: null,
                filter_by_surname: null
            }

        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        exportExcel() {

        },
        async getRows() {
            this.datatable.showTable = true
            this.datatable.isLoading = true
            // Date
            if (this.datatable.queryParams.filter.date?.start && this.datatable.queryParams.filter.date?.end) {
                this.datatable.queryParams.filter.check_in_date = this.datatable.queryParams.filter.date.start
                this.datatable.queryParams.filter.check_out_date = this.datatable.queryParams.filter.date.end
            }

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => {
                    const cleanParams = {...params};
                    delete cleanParams.filter.date;
                    return qs.stringify(cleanParams, {encode: false});
                }
            }

            return HousingReservationsService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .catch((e) => {
                    this.showErrors(e);
                })
                .finally(() => {
                        this.datatable.isLoading = false;
                    }
                );
        },
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        updateFormShow(selectedRow) {
            this.formProcess = 'reservationModal';
            this.selectedReservation = selectedRow;
            this.$refs.successModal.$refs.commonModal.show();
        },
        successfully() {
            this.formProcess = null

            this.$refs.successModal.$refs.commonModal.hide();
            this.getRows();
        }
    },
}
</script>
