<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('airport_transfers_list')"
                        :is-filter="false"
                >
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('airport_transfers_list')"
                              :is-filter="false"

                >
                </HeaderMobile>
            </template>

            <vue-cal
                :events="events"
                active-view="month"
                :disable-views="['years','year']"
                :time-step="60"
                events-on-month-view
                :selected-date="selectedDate"
                @view-change="onViewChange"
                class="custom-vuecal"
                :on-event-click="showVueCalModal"
                :cell-height="cellHeight"
            >
                <template #event="{ event }">
                    <div
                        class="vuecal__event-title border"
                        :style="{ backgroundColor: event.color, color: '#fff' }"
                        v-html="event.title"
                    ></div>

                    <em class="vuecal__event-time">
                        <strong>Arrival Date:</strong><br>
                        <span>{{ toLocaleDateTime(event.start) }}</span>
                        <br/>
                        <span>
                            <strong>Student Name:</strong><br>
                        <span>{{ event.name ?? '-' }}</span>
                        </span>
                        <br>

                        <span>
                            <strong>Accompany Name:</strong><br>
                        <span>{{ event.accompany_name_surname ?? '-' }}</span>
                        </span>
                    </em>
                    <br/>

                </template>
            </vue-cal>
        </app-layout>
        <CommonModal ref="AddModal" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('detail') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <ValidationObserver ref="refundRequestForm">
                        <b-col>
                            <ValidationProvider name="accompany_name_surname" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('accompany_name_surname')">
                                    <b-form-input type="text"
                                                  v-model="form.accompany_name_surname"
                                                  :state="errors[0] ? false : null">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider name="accompany_phone" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('accompany_phone')">
                                    <b-form-input
                                        v-model="form.accompany_phone"
                                        v-mask="'+1 (###)-###-####'"
                                        :state="errors[0] ? false : null">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider name="accompany_email" rules="required|email" v-slot="{valid, errors}">
                                <b-form-group :label="$t('accompany_email')">
                                    <b-form-input type="text"
                                                  v-model="form.accompany_email"
                                                  :state="errors[0] ? false : null">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('route')">
                                <div class="label-as-input">{{ isNotNullValue(form.transfer_places_name) }}</div>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('name')">
                                <b-form-input type="text"
                                              v-model="form.name"
                                              readonly
                                >
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('mobile_number')">
                                <b-form-input type="text"
                                              v-model="form.mobile_tel"
                                              readonly
                                >
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('email')">
                                <b-form-input type="text"
                                              v-model="form.email"
                                              readonly
                                >
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <div class="d-flex justify-content-center">
                            <b-button @click="save">{{ $t('save') }}</b-button>

                        </div>
                    </ValidationObserver>
                </div>
            </template>
        </CommonModal>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
// Components
// Others
import moment from 'moment';

import qs from 'qs'
import HousingRequestListServices from "@/services/HousingRequestListServices";
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import '/src/locales/en/vuecal.js'
import HousingAirportReservationService from "@/services/HousingAirportReservationService";

export default {
    components: {
        AppLayout,
        Header,
        VueCal,
        HeaderMobile,
    },
    metaInfo() {
        return {
            title: this.$t('airport_transfers_list')
        }
    },
    data() {
        return {
            eventId: null,
            events: [],
            cellHeight: 500,
            loading: false,
            startDate: null,
            filter: {
                request_type: 'airport_transfer_request',
                status: 'approved',
                by_month: null
            },
            form: {
                email: null,
                name: null,
                mobile_tel: null,
                accompany_name_surname: null,
                accompany_phone: null,
                accompany_email: null,
                transfer_places: null,
                transfer_places_name: null,
            },
            currentMonth: null,
            currentYear: null
        }
    },
    computed: {
        selectedDate() {
            const date = this.filter.by_month?.split(',')
            if (date) {
                return `${date[1]}-${date[0]}-01`
            }
            return ''
        },
    },
    mounted() {
        this.dateFormat()
    },
    methods: {
        dateFormat() {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            this.filter.by_month = `${month},${year}`;
            this.getRows()
        },
        getRandomColor(event) {
            let letters;
            if (event.request_data?.transfer_places === 'from_airport_to_island_condos') {
                letters = 'F02121'
            } else {
                letters = '00FF00'
            }
            let color = '#';
            color += letters;
            return color;
        },
        onViewChange({startDate, view}) {
            if (view !== 'week') {
                this.setCurrentMonthAndYear(startDate);
            }
        },
        addTime(date, time) {
            const formattedDate = moment(date, 'MM/DD/YYYY HH:mm')
                .add(time, 'minutes')
                .format('MM/DD/YYYY HH:mm');
            return formattedDate;
        },

        setCurrentMonthAndYear(date) {
            this.currentMonth = date?.getMonth() + 1;
            this.currentYear = date?.getFullYear();
            this.filter.by_month = this.currentMonth + ',' + this.currentYear;
            this.getRows()
        },
        getRows() {
            const config = {
                params: {
                    filter: {
                        ...this.filter
                    },
                    limit: -1

                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            return HousingRequestListServices.getAllAdmin(config)
                .then((response) => {
                    const data = response.data.data;
                    this.events = data.map((event) => ({
                        title: 'Flight Number: ' + event.request_data.flight_code,
                        start: event.request_data.arrival_date||event.request_data.departure_date,
                        end: this.addTime(event.request_data.arrival_date||event.request_data.departure_date, 360),
                        color: this.getRandomColor(event),
                        accompany_name_surname: event.request_data.accompany_name_surname ?? null,
                        accompany_phone: event.request_data.accompany_phone ?? null,
                        accompany_email: event.request_data.accompany_email ?? null,
                        transfer_places_code: event.request_data.transfer_places ?? null,
                        transfer_places_name: event.request_data.transfer_places_name ?? null,
                        name: event.name ? (event.name + ' ' + event.surname) : '-',
                        mobile_tel: event.mobile_tel ? ((event.mobile_tel_country_code ?? '') + '' + event.mobile_tel) : '-',
                        email: event.email ? event.email : '-',
                        id: event.id,
                        style: 'height: 100px;'
                    })).sort((a, b) => new Date(a.start) - new Date(b.start))
                }).catch(error => {
                    this.showErrors(error)
                })
        },
        showVueCalModal(event, e) {
            this.eventId = event.id;
            this.form.accompany_name_surname = event.accompany_name_surname;
            this.form.accompany_phone = event.accompany_phone;
            this.form.accompany_email = event.accompany_email;
            this.form.name = event.name;
            this.form.email = event.email;
            this.form.transfer_places = event.transfer_places;
            this.form.transfer_places_name = event.transfer_places_name;
            this.form.mobile_tel = event.mobile_tel;
            this.$refs.AddModal.$refs.commonModal.show()
        },
        formClear() {
            this.eventId = null;
            this.$refs.AddModal.$refs.commonModal.hide()
            this.form = {
                accompany_name_surname: null,
                accompany_phone: null,
                accompany_email: null,
                name: null,
                mobile_tel: null,
                email: null,
            }
        },
        save() {
            const config = {
                request_data: {
                    ...this.form
                },
            };
            return HousingAirportReservationService.addAccompany(this.eventId, config)
                .then((response) => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.formClear()
                    this.getRows()
                }).catch(error => {
                    this.showErrors(error)
                })
        }
    }
}
</script>
<style>
.vuecal__cell {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100px; /* Başlangıç minimum yüksekliği */
    height: auto; /* İçeriğe göre yüksekliği esnetir */
    transition: 0.15s ease-in-out background-color;
}

</style>

