const routes = [
    {
        path: "/my-housing-reservations",
        component: () => import("../pages/Index.vue"),
        meta: {
            isStudent: false,
            isAuthenticated: false,
        },
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))??null;
            const activeRole = user?.active_role?.name
            if (activeRole==='A') {
                return next({path: "/403"});
            }
            next();
        },
    },
];

export default routes;
