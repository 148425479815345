<template>
    <b-container>

        <h2>Bed Holds</h2>

        <p>If you will travel outside of Washington, DC but plan to return to Island Condos and would like to stay in the
            same room, you may request that we hold your room. <b>Please note:</b> a request is <b><u>not</u></b> a guarantee,
            and approval is contingent on availability.</p>

        <p><a href="https://docs.google.com/document/d/1Nq4r7DZj22dg-_5eZ8xyiP-aR0JMKcwuzUfPqWyTtXw/edit"><b>Bed Hold
            Request Form</b></a></p>

        <h2>Bed Hold Fees</h2>

        <p>September 1<sup>st</sup> – May 30<sup>th</sup>: Full monthly fee</p>
        <p>June 1<sup>st</sup> – August 31<sup>st</sup>: Half of the monthly fee</p>

        <p>Example:</p>

        <p>If you are staying in a room that costs $1050 per month and you are traveling in December, you will need to pay
            the full $1050 to hold your room.</p>

        <p>If you are staying in a room that costs $1050 per month and you are traveling in July, you will need to pay $525
            to hold your room.</p>

        <h2 class="subtitle">Expected Behavior of Island Condo Tenants</h2>

        <p><em>Housing firmly believes that residents shall not engage in actions that impede a roommate’s ability to occupy
            a space or room. Further, residents shall not infringe on the rights of other community members or avert others
            from experiencing a safe, respectful, and hygienic environment.</em></p>

        <h2>Compliance with Student Housing Staff</h2>

        <p>Student Housing’s primary means of communication with current residents is through email. Failure to monitor the
            email address provided will not exempt residents from adhering to information and deadlines communicated.</p>

        <p>All residents are expected to cooperate with staff and adhere to reasonable requests. If you have a concern about
            the validity of a request, residents are expected to comply in the moment and to contact the Director of Student
            Services as soon as possible.</p>

        <p>Verbal abuse, physical intimidation, or menacing behaviors directed towards staff members, the display of
            materials that demean staff, and/or interfere with staff members engaged in the performance of assigned
            responsibilities are prohibited.</p>

        <p>The use of physical force of any kind against staff is strictly prohibited and will result in:</p>

        <ol>
            <li>Forfeiture of security deposit.</li>
            <li>Report to law enforcement.</li>
            <li>Eviction from Island Condos.</li>
            <li>If your stay is prepaid, no funds will be returned.</li>
            <li>If your stay is monthly, your removal does not exempt you from the housing contract, and you will be expected
                to remit payment for the remainder of your contract with Island Condos.
            </li>
        </ol>

        <h2>Guest Policy</h2>

        <p>Outside guests are not permitted to enter either the private rooms or the common areas of Island Condos. If you
            are found to be entertaining guests who are not also residents of Island Condos:</p>

        <p><u>First infraction:</u> Written warning</p>

        <p><u>Second infraction:</u> Eviction and forfeiture of security deposit.</p>

        <p>Island Condo residents may visit with friends from other units in common areas <b><u>ONLY</u></b> until
            designated quiet hours.</p>

        <h2>Noise Policy</h2>

        <p>Designated quiet hours are 10:00 PM – 7:00 AM, Sunday – Thursday, and 12:00 AM (midnight) – 7:00 AM, Friday and
            Saturday.</p>

        <p>Students are encouraged to settle minor noise issues amongst themselves before approaching staff; however, if you
            do not feel comfortable for any reason or the situation seems to be escalating, please contact the security office
            for assistance.</p>

        <h2>Activities During Quiet Hours</h2>

        <p>Activities and events which may produce higher than average noise levels may be scheduled during designated quiet
            hours with expressed approval from Student Housing Management and advanced notice to the community. If you would
            like to plan an event, please send your request to <a
                href="mailto:studenthousing@bau.edu">studenthousing@bau.edu</a>.</p>

        <p>Please allow up to 48 business hours for your request to be processed.</p>

        <p>Please note, you are responsible for your guests and will be held liable for any damages or complaints.</p>

        <h2>Cleaning Policy</h2>

        <p>Tenants are responsible for maintaining a hygienic environment in their personal rooms, bathrooms, and common
            spaces. If unreasonable levels of clutter are observed during routine inspections, the tenant will be given a
            written warning and a deadline for rectifying the situation. If the situation continues unaddressed, the security
            deposit will be forfeited and, in extreme cases, the tenant may be removed from student housing.</p>

        <p>You are expected to keep all common areas clean.</p>

        <ul>
            <li>Do not leave dirty dishes in the sink or on counters.</li>
            <li>Empty the dishwasher.</li>
            <li>Take out the trash.</li>
            <li>Clean out tubs and wipe down surfaces in bathrooms.</li>
        </ul>

        <p>Unattended food, dirty dishes, and trash attract pests. Student Housing provides regular pest control
            maintenance; however, if it is found that additional services are required as a result of unhygienic conditions,
            the cost of those services will be deducted from the security deposits of the residents of the unit in question
            and/or being treated.</p>

        <p>If there are multiple units in violation of the cleaning policy, the costs of additional pest control services
            will be divided evenly among the residents of the building.</p>

        <h2>Cleaning Service</h2>

        <p>A cleaning service has been contracted to clean common areas bi-monthly. Their sole responsibilities are the
            oven, the microwave, kitchen countertops, and kitchen and bathroom floors.</p>

        <p>If your bathroom, kitchen, or the common areas of your building are unreasonably cluttered, you will receive a
            written warning, and cleaning services will not be rendered until the space(s) are tidied.</p>

        <p>Failure to perform necessary cleaning after your written warning will result in probation.</p>

        <h2>Maintenance Repairs</h2>

        <p>Maintenance emergencies are defined as situations that, if left unresolved, could result in injury, threaten
            one’s health, or cause serious property damage in the very short term. If you are experiencing:</p>

        <ul>
            <li>Fire</li>
            <li>Flooding</li>
            <li>Gas leak</li>
            <li>Carbon monoxide</li>
            <li>No electricity</li>
            <li>No water</li>
            <li>No heat when temperatures are below 55 degrees F</li>
        </ul>

        <p>This constitutes a ‘maintenance emergency’ and you should report immediately to the security office.</p>

        <p>If you are experiencing a maintenance issue that is not an emergency, please send an email to <a
            href="mailto:studenthousing@bau.edu">studenthousing@bau.edu</a>. It is your responsibility to report maintenance
            issues as quickly as possible.</p>

        <b-col>
            <ValidationObserver ref="formModalValidate">
                <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                    <div class="mr-3">
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="form.status"
                            name="checkbox-1"
                            value="accepted"
                            :unchecked-value="null"
                        >
                            I have read, understood, and accept
                        </b-form-checkbox>
                        <b-form-invalid-feedback v-if="errors[0]"
                                                 v-html="errors[0]">

                        </b-form-invalid-feedback>
                    </div>
                </ValidationProvider>
                <div class="d-flex justify-content-center  align-center mt-3 mb-3">
                    <b-button @click="next" variant="primary" class="mr-3">{{
                            $t('next')
                        }}
                    </b-button>
                </div>
            </ValidationObserver>
        </b-col>


    </b-container>
</template>
<script>
export default {

    data() {
        return {
            form: {
                status:null,
            }
        }
    },
    methods:{
        async next() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.$emit('next', 1)
            }

        },
    }
}
</script>
<style>
@import ".././Style/Style.css";
</style>
