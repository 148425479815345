<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="2" lg="2">
                    <ValidationProvider name="code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('code')">
                            <b-form-input type="text" v-model="formData.code"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="5" lg="5">
                    <ValidationProvider name="faculty_code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="formData.faculty_code"
                                               :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="5" lg="5">
                    <ValidationProvider name="department_code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('department')">
                            <department-selectbox v-model="formData.department_code"
                                                  :faculty_code="formData.faculty_code"
                                                  :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12">
                    <b-form-group :label="$t('name')">
                        <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" >
                                <b-form-input v-model="formData.name"
                                              :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group :label="$t('short_name')">
                        <ValidationProvider name="short_name" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" >
                                <b-form-input v-model="formData.short_name"
                                              :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group label="Diploma and Transcript Name">
                        <ValidationProvider name="diploma_name" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" >
                                <b-form-input v-model="formData.diploma_name"
                                              :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group :label="$t('diploma_title')">
                        <ValidationProvider name="diploma_title" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" >
                                <b-form-input v-model="formData.diploma_title"
                                              :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group :label="$t('card_name')">
                        <ValidationProvider name="card_name" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" >
                                <b-form-input v-model="formData.card_name"
                                              :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider name="level" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('level')">
                            <parameter-selectbox v-model="formData.level"
                                                 :validateError="errors[0]"
                                                 code="program_levels"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider name="major_type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('major_type')">
                            <parameter-selectbox v-model="formData.major_type"
                                                 :validateError="errors[0]"
                                                 code="major_types"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider name="mode_of_study" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('mode_of_study')">
                            <parameter-selectbox v-model="formData.mode_of_study"
                                                 :validateError="errors[0]"
                                                 code="mode_of_studies"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
<!--                <b-col cols="12" md="4" lg="3">-->
<!--                    <ValidationProvider name="language" rules="required" v-slot="{valid, errors}">-->
<!--                        <b-form-group :label="$t('language')">-->
<!--                            <language-selectbox v-model="formData.language"-->
<!--                                                :validateError="errors[0]"-->
<!--                            />-->
<!--                        </b-form-group>-->
<!--                    </ValidationProvider>-->
<!--                </b-col>-->
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider type="number" name="duration" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('duration')">
                            <b-form-input v-model="formData.duration"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider type="number" name="max_duration" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('max_duration')">
                            <b-form-input v-model="formData.max_duration"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider name="credit_limit" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('credit_limit')">
                            <b-form-input type="number"
                                          v-model="formData.credit_limit"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider name="student_number_format" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('student_number_format')">
                            <b-form-input v-model="formData.student_number_format"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider name="with_thesis" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('with_thesis')">
                            <thesis-selectbox v-model="formData.with_thesis"
                                              :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="head_of_program" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('head_of_program')">
                            <staff-auto-complete v-model="formData.head_of_program"
                                                 :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider name="passing_grade" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('passing_grade')">
                            <grade-selectbox v-model="formData.passing_grade"
                                             :grading-system-id-required="false"
                                             :point-greater-than="0"
                                             value-type="grade"
                                                 :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider name="status" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('status')">
                            <status-selectbox v-model="formData.status"
                                              :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="createForm" :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import DepartmentSelectbox from '@/components/interactive-fields/DepartmentSelectbox';
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox';
    import LanguageSelectbox from '@/components/interactive-fields/LanguageSelectbox';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import StaffAutoComplete from '@/components/interactive-fields/StaffAutoComplete';
    import StatusSelectbox from '@/components/interactive-fields/StatusSelectbox';
    import ThesisSelectbox from '@/components/interactive-fields/ThesisSelectbox';

    // Services
    import ProgramService from '@/services/ProgramService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import GradeSelectbox from "@/components/interactive-fields/GradeSelectbox.vue";

    export default {
        components: {
            GradeSelectbox,
            DepartmentSelectbox,
            FacultySelectbox,
            LanguageSelectbox,
            ParameterSelectbox,
            StaffAutoComplete,
            StatusSelectbox,
            ThesisSelectbox,
            ValidationObserver,
            ValidationProvider
        },
        data() {
            return {
                formData: {
                    code: null,
                    faculty_code: null,
                    department_code: null,
                    name: null,
                    short_name: null,
                    diploma_name: null,
                    diploma_title: null,
                    card_name: null,
                    level: null,
                    major_type: null,
                    mode_of_study: null,
                    language: null,
                    duration: null,
                    max_duration: null,
                    student_number_format: null,
                    credit_limit: null,
                    with_thesis: null,
                    head_of_program: null,
                    passing_grade: null,
                    status: null
                },
                formLoading: false
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    ProgramService.store(this.formData)
                                  .then(response => {
                                      this.$toast.success(this.$t('api.' + response.data.message));
                                      this.$emit('createFormSuccess', true);
                                  })
                                  .catch(e => {
                                      this.showErrors(e, this.$refs.formModalValidate);
                                  })
                                  .finally(() => {
                                      this.formLoading = false
                                  })
                }
            }
        }
    }
</script>

