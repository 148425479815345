<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header
                    :title="$t('students')"
                    @filter-div-status="datatable.filterStatus = $event"
                />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile
                    :title="$t('students')"
                    @filter-div-status="datatable.filterStatus = $event"
                />
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                :filterButtonClass="'mt-1'"
                @filter="filter"
                @filterClear="filterClearAndGetRow"
                :exportExcel="checkPermission('studentprogram_excelexport')"
                @exportExcel="exportExcel"
            >
                <div>
                    <b-tabs
                        content-class="py-5 position-relative"
                        class="line-tabs"
                    >
                        <div class="tabs-bottom-line"></div>
                        <b-tab active>
                            <template #title>
                                <span v-html="computeTab4Name"></span>
                            </template>
                            <b-row>
                                <b-col cols="12" md="4" lg="3">
                                    <b-form-group :label="$t('student_id')">
                                        <b-form-input
                                            v-model="
                                                datatable.queryParams.filter
                                                    .student_number
                                            "
                                            v-check-min-max-value
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3">
                                    <b-form-group :label="$t('first_name')">
                                        <b-form-input
                                            v-model="
                                                datatable.queryParams.filter
                                                    .name
                                            "
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3">
                                    <b-form-group :label="$t('last_name')">
                                        <b-form-input
                                            v-model="
                                                datatable.queryParams.filter
                                                    .surname
                                            "
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3">
                                    <b-form-group :label="$t('application_number')">
                                        <b-form-input
                                            v-model="
                                                datatable.queryParams.filter
                                                    .application_number
                                            "
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3">
                                    <b-form-group :label="$t('gender')">
                                        <gender-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .gender
                                            "
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3">
                                    <b-form-group :label="$t('nationality')">
                                        <country-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .nationality_code
                                            "
                                            valueType="code"
                                        ></country-selectbox>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3">
                                    <b-form-group
                                        :label="$t('passport_number')"
                                    >
                                        <b-form-input
                                            v-model="
                                                datatable.queryParams.filter
                                                    .passport_number
                                            "
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3">
                                    <b-form-group :label="$t('year_of_birth')">
                                        <select-date v-model="datatable.queryParams.filter.birthdate"
                                                     :readonly="false"/>
                                    </b-form-group>
                                </b-col>

                            </b-row>
                        </b-tab>

                        <b-tab>
                            <template #title>
                                <span v-html="computeTab1Name"></span>
                            </template>
                            <b-row>
                                <b-col cols="12" md="6" lg="4">
                                    <b-form-group :label="$t('school')">
                                        <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"/>
                                    </b-form-group>
                                    <b-form-group :label="$t('department')">
                                        <department-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .department_code
                                            "
                                            :faculty_code="
                                                datatable.queryParams.filter
                                                    .faculty_code
                                            "
                                        ></department-selectbox>
                                    </b-form-group>
                                    <b-form-group :label="$t('program')">
                                        <program-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .program_code
                                            "
                                            :faculty_code="
                                                datatable.queryParams.filter
                                                    .faculty_code
                                            "
                                            :department_code="
                                                datatable.queryParams.filter
                                                    .department_code
                                            "
                                        />
                                    </b-form-group>
                                    <b-form-group
                                        :label="$t('re_enrollment')"
                                    >
                                        <yes-no-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .re_enrollment
                                            "
                                        ></yes-no-selectbox>
                                    </b-form-group>

                                </b-col>

                                <b-col cols="12" md="6" lg="4">
                                    <b-form-group
                                        :label="$t('registration_type_1')"
                                    >
                                        <parameter-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .registration_type
                                            "
                                            code="registration_types"
                                        ></parameter-selectbox>
                                    </b-form-group>

                                    <b-form-group
                                        :label="$t('legal_presence_type')"
                                    >
                                        <parameter-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .legal_presence_type
                                            "
                                            code="legal_presence_types"
                                        ></parameter-selectbox>
                                    </b-form-group>

                                    <b-form-group
                                        :label="$t('credit_type')"
                                    >
                                        <parameter-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .credit_type
                                            "
                                            code="credit_types"
                                        ></parameter-selectbox>
                                    </b-form-group>

                                    <b-form-group
                                        :label="$t('change_of_program')"
                                    >
                                        <yes-no-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .change_of_program
                                            "
                                        ></yes-no-selectbox>
                                    </b-form-group>

                                </b-col>
                                <b-col cols="12" md="6" lg="4">
                                    <b-form-group :label="$t('mode_of_study')">
                                        <parameter-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .mode_of_study
                                            "
                                            code="mode_of_studies"
                                        ></parameter-selectbox>
                                    </b-form-group>
                                    <b-form-group :label="$t('class')">
                                        <parameter-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .class
                                            "
                                            code="classes"
                                        />
                                    </b-form-group>
                                    <b-form-group
                                        :label="$t('credit_transfer')"
                                    >
                                        <yes-no-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .credit_transfer
                                            "
                                        ></yes-no-selectbox>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-tab>
                        <b-tab>
                            <template #title>
                                <span v-html="computeTab3Name"></span>
                            </template>
                            <b-row>
                                <b-col cols="12" md="6">
                                    <b-form-group :label="$t('student_status')">
                                        <parameter-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .student_status
                                            "
                                            code="student_statuses"
                                        ></parameter-selectbox>
                                    </b-form-group>
                                    <b-form-group
                                        :label="$t('semester_status')"
                                    >
                                        <parameter-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .active_semester_status
                                            "
                                            code="semester_statuses"
                                        ></parameter-selectbox>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="6">
                                    <b-form-group
                                        :label="$t('number_of_semesters')"
                                    >
                                        <period-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .number_of_semesters
                                            "
                                        ></period-selectbox>
                                    </b-form-group>
                                    <b-form-group :label="$t('gna_not_range')">
                                        <gno-range
                                            :gno_range_start.sync="
                                                datatable.queryParams.filter
                                                    .gno_range_start
                                            "
                                            :gno_range_end.sync="
                                                datatable.queryParams.filter
                                                    .gno_range_end
                                            "
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3">
                                    <b-form-group :label="$t('payment_status')">
                                        <parameter-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .payment_status
                                            "
                                            code="payment_statuses"
                                        ></parameter-selectbox>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="4" lg="3">
                                    <b-form-group :label="$t('course_registration_completed')">
                                        <yes-no-selectbox
                                            v-model="
                                                datatable.queryParams.filter
                                                    .course_registration_completed
                                            "
                                        ></yes-no-selectbox>
                                    </b-form-group>
                                </b-col>

                            </b-row>
                        </b-tab>


                        <b-tab>
                            <template #title>
                                <span v-html="computeTab2Name"></span>
                            </template>
                            <b-row>
                                <b-col cols="12" sm="6" md="4">
                                    <h5>
                                        {{ $t("initial_enrollment_information") }}
                                    </h5>
                                    <div class="border rounded p-4">
                                        <b-form-group
                                            :label="
                                                $t('registration_academic_year')
                                            "
                                        >
                                            <academic-years-selectbox
                                                v-model="
                                                    datatable.queryParams.filter
                                                        .registration_academic_year
                                                "
                                            ></academic-years-selectbox>
                                        </b-form-group>
                                        <b-form-group
                                            :label="$t('initial_enrollment_semester')"
                                        >
                                            <parameter-selectbox
                                                v-model="
                                                    datatable.queryParams.filter
                                                        .registration_semester
                                                "
                                                code="semester"
                                                sort="code"
                                            ></parameter-selectbox>
                                        </b-form-group>
                                        <b-form-group
                                            :label="
                                                $t('registration_date_between')
                                            "
                                            class="mb-0"
                                        >
                                            <v-date-picker
                                                v-model="
                                                    datatable.queryParams.filter
                                                        .registration_date_between
                                                "
                                                is-range
                                                locale="en"
                                            >
                                                <template
                                                    v-slot="{
                                                        inputValue,
                                                        inputEvents,
                                                    }"
                                                >
                                                    <div
                                                        class="d-flex align-items-center"
                                                    >
                                                        <div
                                                            class="flex-grow-1"
                                                        >
                                                            <b-form-group
                                                                class="m-0 mr-1"
                                                            >
                                                                <input
                                                                    :value="
                                                                        inputValue.start
                                                                    "
                                                                    v-on="
                                                                        inputEvents.start
                                                                    "
                                                                    :placeholder="
                                                                        $t(
                                                                            'start_date'
                                                                        )
                                                                    "
                                                                    class="form-control"
                                                                />
                                                            </b-form-group>
                                                        </div>
                                                        <div
                                                            class="flex-grow-1"
                                                        >
                                                            <b-form-group
                                                                class="m-0 ml-1"
                                                            >
                                                                <input
                                                                    :value="
                                                                        inputValue.end
                                                                    "
                                                                    v-on="
                                                                        inputEvents.end
                                                                    "
                                                                    :placeholder="
                                                                        $t(
                                                                            'end_date'
                                                                        )
                                                                    "
                                                                    class="form-control"
                                                                />
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="6" md="4">
                                    <h5>{{ $t("graduation_information") }}</h5>
                                    <div class="border rounded p-4">
                                        <b-form-group
                                            :label="
                                                $t('graduation_academic_year')
                                            "
                                        >
                                            <academic-years-selectbox
                                                v-model="
                                                    datatable.queryParams.filter
                                                        .graduation_academic_year
                                                "
                                            ></academic-years-selectbox>
                                        </b-form-group>
                                        <b-form-group
                                            :label="$t('graduation_semester')"
                                        >
                                            <parameter-selectbox
                                                v-model="
                                                    datatable.queryParams.filter
                                                        .graduation_semester
                                                "
                                                code="semester"
                                                sort="code"
                                            ></parameter-selectbox>
                                        </b-form-group>
                                        <b-form-group
                                            :label="
                                                $t('diploma_date_range')
                                            "
                                            class="mb-0"
                                        >
                                            <v-date-picker
                                                v-model="
                                                    datatable.queryParams.filter
                                                        .graduation_date_between
                                                "
                                                is-range
                                                locale="en"
                                            >
                                                <template
                                                    v-slot="{
                                                        inputValue,
                                                        inputEvents,
                                                    }"
                                                >
                                                    <div
                                                        class="d-flex align-items-center"
                                                    >
                                                        <div
                                                            class="flex-grow-1"
                                                        >
                                                            <b-form-group
                                                                class="m-0 mr-1"
                                                            >
                                                                <input
                                                                    :value="
                                                                        inputValue.start
                                                                    "
                                                                    v-on="
                                                                        inputEvents.start
                                                                    "
                                                                    :placeholder="
                                                                        $t(
                                                                            'start_date'
                                                                        )
                                                                    "
                                                                    class="form-control"
                                                                />
                                                            </b-form-group>
                                                        </div>
                                                        <div
                                                            class="flex-grow-1"
                                                        >
                                                            <b-form-group
                                                                class="m-0 ml-1"
                                                            >
                                                                <input
                                                                    :value="
                                                                        inputValue.end
                                                                    "
                                                                    v-on="
                                                                        inputEvents.end
                                                                    "
                                                                    :placeholder="
                                                                        $t(
                                                                            'end_date'
                                                                        )
                                                                    "
                                                                    class="form-control"
                                                                />
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="6" md="4">
                                    <h5>{{ $t("leaving_information") }}</h5>
                                    <div class="border rounded p-4">
                                        <b-form-group
                                            :label="$t('leaving_academic_year')"
                                        >
                                            <academic-years-selectbox
                                                v-model="
                                                    datatable.queryParams.filter
                                                        .leaving_academic_year
                                                "
                                            ></academic-years-selectbox>
                                        </b-form-group>
                                        <b-form-group
                                            :label="$t('leaving_semester')"
                                        >
                                            <parameter-selectbox
                                                v-model="
                                                    datatable.queryParams.filter
                                                        .leaving_semester
                                                "
                                                code="semester"
                                                sort="code"
                                            ></parameter-selectbox>
                                        </b-form-group>
                                        <b-form-group
                                            :label="$t('leaving_date_between')"
                                        >
                                            <v-date-picker
                                                v-model="
                                                    datatable.queryParams.filter
                                                        .leaving_date_between
                                                "
                                                is-range
                                                locale="en"
                                            >
                                                <template
                                                    v-slot="{
                                                        inputValue,
                                                        inputEvents,
                                                    }"
                                                >
                                                    <div
                                                        class="d-flex align-items-center"
                                                    >
                                                        <div
                                                            class="flex-grow-1"
                                                        >
                                                            <b-form-group
                                                                class="m-0 mr-1"
                                                            >
                                                                <input
                                                                    :value="
                                                                        inputValue.start
                                                                    "
                                                                    v-on="
                                                                        inputEvents.start
                                                                    "
                                                                    :placeholder="
                                                                        $t(
                                                                            'start_date'
                                                                        )
                                                                    "
                                                                    class="form-control"
                                                                />
                                                            </b-form-group>
                                                        </div>
                                                        <div
                                                            class="flex-grow-1"
                                                        >
                                                            <b-form-group
                                                                class="m-0 ml-1"
                                                            >
                                                                <input
                                                                    :value="
                                                                        inputValue.end
                                                                    "
                                                                    v-on="
                                                                        inputEvents.end
                                                                    "
                                                                    :placeholder="
                                                                        $t(
                                                                            'end_date'
                                                                        )
                                                                    "
                                                                    class="form-control"
                                                                />
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                        <b-form-group
                                            :label="$t('reason_for_leaving')"
                                        >
                                            <parameter-selectbox
                                                v-model="
                                                    datatable.queryParams.filter
                                                        .leave_with_consent_reason
                                                "
                                                code="leave_with_consent_reasons"
                                            ></parameter-selectbox>
                                        </b-form-group>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-tab>


                    </b-tabs>
                </div>

            </datatable-filter>

            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            />
            <CommonModal
                ref="showDocumentModal"
                size="lg"
                @bHideModalHeaderClose="closedDocumentModal"
                :onHideOnlyX="true"
            >
                <template v-slot:CommonModalTitle>
                    {{ $t("documents").toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <div
                        class="mb-2 border rounded p-2 text-center bg-dark text-white"
                    >
                        {{ user.student_number }} - {{ user.name }}
                        {{ user.surname }}
                    </div>
                    <b-row>
                        <b-col
                            cols="6"
                            v-for="(item, itemKey) in computeDocuments"
                            :key="'document-' + itemKey"
                        >
                            <b-list-group class="pe-auto">
                                <b-list-group-item
                                    v-for="(lang, langKey) in item.language"
                                    :key="'lang-' + itemKey + '-' + langKey"
                                    class="mb-1 text-center"
                                    :style="{ cursor: 'pointer' }"
                                    @click="item.callback(lang)"
                                >
                                    {{ item.text.toUpperCase() }}
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                    </b-row>
                </template>
            </CommonModal>
            <CommonModal
                ref="showChangeProgramModal"
                size="lg"
                @bHideModalHeaderClose="closedChangeProgramModal"
                :onHideOnlyX="true"
            >
                <template v-slot:CommonModalTitle>
                    {{ $t("documents").toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <change-program-form :studentProgramId="studentProgramId"
                                         v-if="formProgress=='changeProgramForm'"
                                         @closedChangeProgramModal="closedChangeProgramModal"/>
                </template>
            </CommonModal>

            <CommonModal ref="studentAttendanceRecordsModal" :onHideOnlyX="true"
                         @bHideModalHeaderClose="closedStudentAttendanceRecords">
                <template v-slot:CommonModalTitle>{{ $t('student_attendance_records') }}</template>
                <template v-slot:CommonModalContent>
                    <StudentAttendanceRecords v-if="formProgress==='studentAttendanceRecords'"
                                              :student-program-id="studentProgramId" :student-number="studentNumber"
                                              @closeModal=""/>
                </template>
            </CommonModal>


        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";

// Components
import AcademicYearsSelectbox from "../../../components/interactive-fields/AcademicYearsSelectbox";
import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import GenderSelectbox from "@/components/interactive-fields/GenderSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import PeriodSelectbox from "../../../components/interactive-fields/PeriodSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import CommonModal from "@/components/elements/CommonModal";
import GnoRange from "@/components/elements/GnoRange";

// Services
import StudentProgramService from "@/services/StudentProgramService";
import StudentDocumentService from "@/services/StudentDocumentService";
import PrepStudentService from "@/services/PrepStudentService";

// Data
import documentData from "@/modules/students/data/Documents";

// Other
import qs from "qs";
import {EventBus} from "@/main";
import Base from "@/plugins/Base";
import ChangeProgramForm from "@/modules/students/pages/changeProgramForm.vue";
import StudentAttendanceRecords from "@/modules/students/pages/StudentAttendanceRecords.vue";
import showErrors from "@/helpers/showErrors";
import Vue from "vue";
import i18n from "@/plugins/i18n";
import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox.vue";

export default {
    components: {
        YesNoSelectbox,
        StudentAttendanceRecords,
        ChangeProgramForm,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,

        AcademicYearsSelectbox,
        CountrySelectbox,
        DepartmentSelectbox,
        FacultySelectbox,
        GenderSelectbox,
        ParameterSelectbox,
        PeriodSelectbox,
        ProgramSelectbox,
        GnoRange,

        CommonModal,
    },
    metaInfo() {
        return {
            title: this.$t("students"),
        };
    },
    computed: {
        userRoles(){
            return   this.$store.getters['auth/getActiveRole']
        },
        computeTab1Name() {
            let count = 0;
            let filters = this.datatable.queryParams.filter;

            if (this.isNotNull(filters.faculty_code)) count++;
            if (this.isNotNull(filters.department_code)) count++;
            if (this.isNotNull(filters.program_code)) count++;
            if (this.isNotNull(filters.registration_type)) count++;
            if (this.isNotNull(filters.mode_of_study)) count++;
            if (this.isNotNull(filters.legal_presence_type)) count++;

            if (count) {
                let filterButton =
                    '<span class="badge badge-primary ml-1">' +
                    count +
                    "</span>";
                return this.$t("admission_data") + filterButton;
            }
            return this.$t("admission_data");
        },
        computeTab2Name() {
            let count = 0;
            let filters = this.datatable.queryParams.filter;

            if (this.isNotNull(filters.registration_academic_year)) count++;
            if (this.isNotNull(filters.registration_semester)) count++;
            if (this.isNotNull(filters.registration_date_between)) count++;
            if (this.isNotNull(filters.graduation_academic_year)) count++;
            if (this.isNotNull(filters.graduation_semester)) count++;
            if (this.isNotNull(filters.graduation_date_between)) count++;
            if (this.isNotNull(filters.leaving_academic_year)) count++;
            if (this.isNotNull(filters.leaving_semester)) count++;
            if (this.isNotNull(filters.leave_with_consent_reason)) count++;
            if (this.isNotNull(filters.leaving_date_between)) count++;

            if (count) {
                let filterButton =
                    '<span class="badge badge-primary ml-1">' +
                    count +
                    "</span>";
                return (
                    this.$t("definitive_enrollment_year_information") +
                    filterButton
                );
            }
            return this.$t("definitive_enrollment_year_information");
        },
        computeTab3Name() {
            let count = 0;
            let filters = this.datatable.queryParams.filter;

            if (this.isNotNull(filters.student_status)) count++;
            if (this.isNotNull(filters.active_semester_status)) count++;
            if (this.isNotNull(filters.number_of_semesters)) count++;
            if (this.isNotNull(filters.gno_range_start)) count++;
            if (this.isNotNull(filters.payment_status)) count++;
            if (this.isNotNull(filters.course_registration_completed)) count++;

            if (count) {
                let filterButton =
                    '<span class="badge badge-primary ml-1">' +
                    count +
                    "</span>";
                return (
                    this.$t("semester_registration_information") + filterButton
                );
            }
            return this.$t("semester_registration_information");
        },
        computeTab4Name() {
            let count = 0;
            let filters = this.datatable.queryParams.filter;

            if (this.isNotNull(filters.student_number)) count++;
            if (this.isNotNull(filters.name)) count++;
            if (this.isNotNull(filters.surname)) count++;
            if (this.isNotNull(filters.gender)) count++;
            if (this.isNotNull(filters.nationality_code)) count++;
            if (this.isNotNull(filters.passport_number)) count++;
            if (this.isNotNull(filters.birthdate)) count++;
            if (this.isNotNull(filters.military_status)) count++;

            if (count) {
                let filterButton =
                    '<span class="badge badge-primary ml-1">' +
                    count +
                    "</span>";
                return this.$t("personal_information") + filterButton;
            }
            return this.$t("personal_information");
        },

        computeDocuments() {
            let documents = [];

            if (this.user && this.user.id && this.user.student_number) {

                documents = documentData(this.user).filter(item=>{
                    return this.checkPermission(item.permission)
                });

            }
            return documents;
        },
    },

    data() {
        return {
            formProgress: null,
            actions: [
                {
                    text: this.$t("delete_all_students"),
                    permission: "prepstudent_bulkdelete",
                    callback: () => {
                        this.$swal
                            .fire({
                                text: this.$t("are_you_sure_to_delete"),
                                showCancelButton: true,
                                confirmButtonText: this.$t("yes"),
                                cancelButtonText: this.$t("no"),
                            })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    const config = {
                                        params: {
                                            ...this.datatable.queryParams.filter,
                                        },
                                        paramsSerializer: (params) =>
                                            qs.stringify(params, {
                                                encode: false,
                                            }),
                                    };
                                    PrepStudentService.bulkDelete(config)
                                        .then((response) => {
                                            this.$toast.success(
                                                this.$t(
                                                    "api." +
                                                    response.data.message
                                                )
                                            );
                                            this.getRows();
                                            this.formClear();
                                        })
                                        .catch((error) => {
                                            this.showErrors(error);
                                        });
                                }
                            });
                    },
                },
            ],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("view_and_edit"),
                                class: "ri-search-eye-line",
                                permission: "studentprogram_show",
                                callback: (row) => {
                                    window.open("/students/" + row.id, '_blank');
                                    // this.$router.push("/students/" + row.id);
                                },
                            },
                            {
                                text: this.$t("payment_info"),
                                class: "ri-money-dollar-circle-line",
                                permission: "customer_customerpayments",
                                callback: (row) => {
                                    window.open("/customers/payments/student/" + row.id, '_blank');
                                },
                            },
                            {
                                text: this.$t("course_registration"),
                                class: "ri-book-line",
                                permission: "courseregistration_getslot",
                                callback: (row) => {
                                    window.open("/course/registration/" + row.id, '_blank')
                                },
                            },
                            {
                                text: this.$t("edit_slot"),
                                class: "ri-edit-circle-fill",
                                permission: "studentslot_update",
                                callback: (row) => {
                                    window.open("/student/programs/" +
                                        row.id +
                                        "/slots/edit", '_blank')
                                },
                            },
                            {
                                text: this.$t("slots"),
                                class: "ri-list-check",
                                permission: "studentprogram_studentslot",
                                callback: (row) => {
                                    window.open("/student/programs/" + row.id + "/slots", '_blank')

                                },
                            },
                            {
                                text: this.$t("documents"),
                                class: "ri-file-list-line",
                                show: () => {
                                 return  this.userRoles!="DDE" && this.userDocumentPerm ? true:false
                                },
                                callback: (row) => {
                                    this.showDocumentModal(row);
                                },
                            },
                            {
                                text: this.$t("student_gno_report"),
                                class: "ri-bar-chart-2-line",
                                permission: "studentdocument_gpacontrolexcelexport",
                                callback: ({id}) => {
                                    StudentDocumentService.downloadGpaStudentExcel(id)
                                        .then((res) => {
                                            this._downloadFile(
                                                res,
                                                this.$t("student_gno_report") +
                                                ".xlsx"
                                            );
                                            this.$toast.success(
                                                this.$t("proccess_success")
                                            );
                                        })
                                        .catch((err) => this.showErrors(err));
                                },
                            },
                            {
                                text: this.$t("official_transcript"),
                                class: "ri-bar-chart-2-line",
                                permission: "studentdocument_officialtranscript",
                                callback: (row) => {
                                    StudentDocumentService.downloadOfficialTranscript(row.id)
                                        .then(response => {
                                            let fileName = row.student_number +' - '+ row.name+' '+row.surname + ' - Official Transcript.pdf'
                                            EventBus.$emit('pdfViewerModalShow', {
                                                pdfFileData: response.data,
                                                pdfFileName: fileName
                                            });
                                        })
                                        .catch(e => {
                                            showErrors(e, null, true)
                                        })
                                },
                            },
                            {
                                text: this.$t("student_certificate"),
                                class: "ri-bar-chart-2-line",
                                permission: "studentdocument_studentcertificate",
                                callback: ({id,student_number}) => {
                                    EventBus.$emit('closeDocumentModal', true);
                                    Vue.swal
                                        .fire({
                                            input: 'textarea',
                                            inputLabel: i18n.t('note_you_want_to_add'),
                                            inputValue: '',
                                            inputPlaceholder: i18n.t('note'),
                                            inputAttributes: {
                                                'aria-label': i18n.t('note')
                                            },
                                            showCancelButton: true
                                        })
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                               const lang='en'
                                                StudentDocumentService.downloadStudentCertificate(id, lang, result.value)
                                                    .then(response => {
                                                        let fileName = student_number + '-' + i18n.t('file_name_student_certificate') +  '.pdf';
                                                        EventBus.$emit('pdfViewerModalShow', {
                                                            pdfFileData: response.data,
                                                            pdfFileName: fileName
                                                        });
                                                    })
                                                    .catch(e => {
                                                        showErrors(e, null, true)
                                                    })
                                            }
                                        })
                                },
                                show:({student_status})=>{
                                    return student_status!='102'?true:false
                                }
                            },
                            {
                                text: this.$t("new_password"),
                                class: "ri-bar-chart-2-line",
                                permission: "studentdocument_newpassword",
                                callback: ({student_number}) => {
                                    const lang="en"
                                    StudentDocumentService.downloadNewPassword(student_number, lang)
                                        .then(response => {
                                            let fileName = student_number+ '-' + i18n.t('file_name_new_password') +  '.pdf'
                                            EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
                                        })
                                        .catch(e => {
                                            showErrors(e, null, true)
                                        })
                                },
                                show:({student_status})=>{
                                    return ['100','101','102','202'].includes(student_status);
                                }
                            },

                            {
                                text: this.$t("gpa_control_report"),
                                class: "ri-checkbox-circle-line",
                                permission: "studentdocument_gpacontrol",
                                callback: (row) => {
                                    StudentDocumentService.downloadGpaControl(
                                        row.id
                                    )
                                        .then((response) => {
                                            this._openFile(response);
                                        })
                                        .catch((e) => {
                                            this.showErrors(e, null, true);
                                        });
                                },
                            },
                            {
                                text: this.$t("change_program"),
                                class: "ri-arrow-up-down-line",
                                permission: "studentprogram_movestudentprogram",
                                callback: (row) => {
                                    this.showChangeProgramModal(row.id)
                                },
                            },
                            {
                                text: this.$t("student_attendance_records_report"),
                                class: "ri-file-chart-line",
                                permission: "studentdocument_dailyattendancelist",
                                callback: (row) => {
                                    this.showStudentAttendanceRecords(row.id, row.student_number)
                                },
                            },
                            {
                                text: this.$t("gno_simulation"),
                                class: "ri-focus-2-line",
                                permission: "studentcoursesimulation_create",
                                callback: ({id}) => {
                                    window.open("/student/courses/simulation/" + id, '_blank')
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-6-line",
                                callback: ({id}) => {
                                    this.$swal
                                        .fire({
                                            text: this.$t(
                                                "are_you_sure_to_delete"
                                            ),
                                            showCancelButton: true,
                                            confirmButtonText: this.$t("yes"),
                                            cancelButtonText: this.$t("no"),
                                        })
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                StudentProgramService.del(id)
                                                    .then(() => {
                                                        this.$toast.success(
                                                            this.$t(
                                                                "proccess_success"
                                                            )
                                                        );
                                                        this.filter();
                                                    })
                                                    .catch((e) =>
                                                        this.showErrors(e)
                                                    );
                                            }
                                        });
                                },
                                show: () => {
                                    // If user is admin
                                    let user = Base.LocalStorage.get("user");
                                    if (user && user.active_role)
                                        return (
                                            user.active_role.id === 1 ||
                                            user.active_role.name === "A"
                                        );
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t("student_number"),
                        field: "student_number",
                    },
                    {
                        label: this.$t("name"),
                        field: "name",
                    },
                    {
                        label: this.$t("surname"),
                        field: "surname",
                    },
                    {
                        label: this.$t("school"),
                        field: "faculty_name",
                    },
                    {
                        label: this.$t("program"),
                        field: "program_name",
                    },
                    {
                        label: this.$t("registration_type"),
                        field: "registration_type_text",
                    },
                    {
                        label: this.$t("student_status"),
                        field: "student_status_text",
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
            user: {},
            studentProgramId: null,
            programCode: null,
            userDocumentPerm: false,
            studentNumber: null
        };
    },
    created() {
        this.$store.dispatch("students/initLayouts");
        // if (this.$store.getters["students/getFilters"]) {
        //     this.datatable.queryParams.filter =
        //         this.$store.getters["students/getFilters"];
        // } else {
        //     this.filterClear();
        // }
    },

    mounted() {
        EventBus.$on("closeDocumentModal", () => {
            this.$refs.showDocumentModal.$refs.commonModal.hide();
        });
        this.$store.dispatch("auth/initUser");
        let user = this.$store.getters["auth/getUser"];
        this.userDocumentPerm = user.permissions.filter((c) => {
            return c.includes("studentdocument_");
        }).length > 0 ? true : false;
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {
                // Tab 1
                faculty_code: null,
                department_code: null,
                program_code: null,
                registration_type: null,
                mode_of_study: null,

                // Tab2
                registration_academic_year: null,
                registration_semester: null,
                registration_date_between: null,
                graduation_academic_year: null,
                graduation_semester: null,
                graduation_date_between: null,
                leaving_academic_year: null,
                leaving_semester: null,
                leave_with_consent_reason: null,
                leaving_date_between: null,

                // Tab3
                student_status: null,
                active_semester_status: null,
                number_of_semesters: null,
                gno_range_start: null,
                gno_range_end: null,
                payment_status: null,
                course_registration_completed: null,

                // Tab4
                student_number: null,
                application_number: null,
                name: null,
                surname: null,
                gender: null,
                nationality_code: null,
                passport_number: null,
                birthdate: null,
                military_status: null,
            };
        },
        filterClearAndGetRow() {
            this.filterClear();
            this.datatable.rows = null;
            this.datatable.total = null;
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            let queryParams = JSON.parse(
                JSON.stringify(this.datatable.queryParams)
            );

            let first = queryParams.filter.gno_range_start;
            let end = queryParams.filter.gno_range_end;

            if (this.datatable.queryParams.filter) {
                this.$store.dispatch(
                    "students/setFilters",
                    this.datatable.queryParams.filter
                );
            }
            if (first != null && first != "" && end != null && end != "") {
                queryParams.filter.cgpa_between =
                    queryParams.filter.gno_range_start +
                    "," +
                    queryParams.filter.gno_range_end;
            }
            delete queryParams.filter.gno_range_start;
            delete queryParams.filter.gno_range_end;

            const config = {
                params: {
                    ...queryParams,
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, {encode: false}),
            };
            StudentProgramService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        // Documents
        showDocumentModal(user) {
            this.user = user;
            this.$refs.showDocumentModal.$refs.commonModal.show();
        },
        closedDocumentModal() {
            this.user = {};
        },
        // Exports
        exportExcel() {
            let queryParams = JSON.parse(
                JSON.stringify(this.datatable.queryParams)
            );

            let first = queryParams.filter.gno_range_start;
            let end = queryParams.filter.gno_range_end;

            if (this.datatable.queryParams.filter) {
                this.$store.dispatch(
                    "students/setFilters",
                    this.datatable.queryParams.filter
                );
            }
            if (first != null && first != "" && end != null && end != "") {
                queryParams.filter.cgpa_between =
                    queryParams.filter.gno_range_start +
                    "," +
                    queryParams.filter.gno_range_end;
            }

            delete queryParams.filter.gno_range_start;
            delete queryParams.filter.gno_range_end;

            const config = {
                params: {
                    ...queryParams,
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, {encode: false}),
            };

            return StudentProgramService.exportExcel(config).then(
                (response) => {
                    this._downloadFile(response, "students.xlsx");
                }
            );
        },
        showChangeProgramModal(student_program_id) {
            this.studentProgramId = student_program_id
            this.formProgress = 'changeProgramForm'
            this.$refs.showChangeProgramModal.$refs.commonModal.show()
        },
        closedChangeProgramModal() {
            this.studentProgramId = null
            this.formProgress = null
            this.$refs.showChangeProgramModal.$refs.commonModal.hide()
            this.getRows()

        },
        showStudentAttendanceRecords(id, studentProgramId) {
            this.formProgress = 'studentAttendanceRecords'
            this.studentProgramId = id
            this.studentNumber = studentProgramId
            this.$refs.studentAttendanceRecordsModal.$refs.commonModal.show()
        },
        closedStudentAttendanceRecords() {
            this.formId = null
            this.formProgress = null
            this.$refs.studentAttendanceRecordsModal.$refs.commonModal.hide()
        },
    },
};
</script>
