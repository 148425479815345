import Api from '@/services/Index';
import axios from "axios";


const getAll = async (config,isTenant) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if (isTenant) {
        const baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
        return axios.get(`${baseURL}housing/reservations/list`, config);
    }
    return Api.get('/housing/reservations/list', config);
}

const studentToken = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/reservations/student/token',data);
}

export default {
    getAll,
    studentToken
}
