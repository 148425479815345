import i18n from '@/plugins/i18n';

export default function (role = 'user',studentProgram={}) {
    let menus = [
        {
            title: i18n.t('general_information'),
            title_short: i18n.t('general'),
            slug: 'general_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('identity_information'),
            title_short: i18n.t('identity'),
            slug: 'identity_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('foreign_student'),
            title_short: i18n.t('foreign_student'),
            slug: 'foreign_student',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('address_information'),
            title_short: i18n.t('address'),
            slug: 'address_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('disability_services'),
            title_short: i18n.t('health'),
            slug: 'health_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('university_informations'),
            title_short: i18n.t('university'),
            slug: 'university_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('contact_information'),
            title_short: i18n.t('contact'),
            slug: 'contact_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('education_information'),
            title_short: i18n.t('education'),
            slug: 'student_education',
            permission: 'studenteducation_index',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('documents'),
            title_short: i18n.t('documents'),
            slug: 'documents',
            permission: 'student_file',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('missing_documents'),
            title_short: i18n.t('missing_documents'),
            slug: 'missing_document',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('discipline_process'),
            title_short: i18n.t('discipline_process'),
            slug: 'discipline_process',
            permission: 'discipline_studentprogramdisciplines',
            show: true,
            showStudent: false
        },
        {
            title: i18n.t('notes'),
            title_short: i18n.t('notes'),
            slug: 'notes',
            permission: 'studentnote_index',
            show: true,
            showStudent: false
        },
        {
            title: i18n.t('logs'),
            title_short: i18n.t('logs'),
            slug: 'logs',
            permission: 'studentprogram_log',
            show: true,
            showStudent: false
        }
    ];

    return menus
}


