<template>
    <div class="m-3 d-flex flex-column">
        <!-- Dropdown for User Profile -->
        <div class=" mb-4 d-flex flex-row align-items-center justify-content-end ">
            <div class="mr-3 ">
                <span >
                    {{tenantUser.name?tenantUser.name:'-'}}
                </span>
                <span >
                    {{tenantUser.surname?tenantUser.surname:'-'}}
                </span>

            </div>
            <b-dropdown
                id="dropdown-form"
                ref="dropdown"
                variant="link"
                class="no-btn"
                right
                no-caret
            >
                <template #button-content>
                    <div
                        class="wh-40-img rounded bg-cover-center"
                        :style="{
                            'background-image': 'url(' + require('@/assets/img/no-photo-2.jpg') + ')',
                        }"
                    >
                    </div>
                </template>
                <b-dropdown-item @click="logoutStudent">Logout</b-dropdown-item>
            </b-dropdown>

        </div>
        <div class="logo-wrapper d-flex justify-content-center mb-4">
            <img src="../../../assets/img/island-candos-logo.png" alt="Logo" class="img-fluid" height="85" />
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            tenantUser:{},
        }
    },
    created() {
        this.tenantUserInformation()
    },
    methods: {
        async tenantUserInformation() {
            const tenantUsers =localStorage.getItem('tenant_user')?JSON.parse(localStorage.getItem('tenant_user')):{}
            this.tenantUser=tenantUsers

        },
        logoutStudent() {
            localStorage.removeItem('token');
            localStorage.removeItem('tenant_user');
            this.$router.push('/housing/tenant/login');
        },
    }
}
</script>

<style scoped>
/* Responsive Styles */
.table-responsive {
    overflow-x: auto;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.wh-40-img {
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position: center;
}

/* Make the logo responsive */
.logo-wrapper img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .text-right {
        text-align: center;
    }

    .wh-40-img {
        width: 30px;
        height: 30px;
    }
}
.d-flex {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}


</style>
