<template>
    <b-container>

        <h2>General Instructions for All Emergency Situations:</h2>

        <p>1. Get out of immediate danger and stay calm.<br>
            2. In the event of a fire, or if you feel the building’s occupants are in danger:<br>
            &nbsp;&nbsp;&nbsp;a. Activate the building’s fire alarm system BEFORE calling 9-1-1.<br>
            &nbsp;&nbsp;&nbsp;b. Evacuate the building immediately!<br>
            &nbsp;&nbsp;&nbsp;c. Use the Building Evacuation Maps on each floor.</p>

        <h3><u>When Calling 9-1-1:</u></h3>

        <p>1. Stay on the line with the dispatcher.</p>
        <p>2. Provide the address of the building involved and your exact location. This is especially important if you are
            calling from a cell phone. Island Condos address is listed below:</p>
        <p>614 Girard Street, NE, Washington, D.C., 20017</p>
        <p>3. Provide a thorough description of the incident to ensure that proper resources are dispatched.</p>
        <p>4. When providing a description of an individual, describe from top (head) to bottom (feet).</p>
        <p>5. Do not hang up until the dispatcher tells you to.</p>

        <h3><u>Notification of Emergencies</u></h3>

        <p>Occupants will be notified of emergencies by either activating the installed fire alarm system, or occupants may
            receive verbal notification of an emergency.</p>

        <h3><u>Fire and Emergency Reporting</u></h3>

        <p>The preferred fire or emergency reporting method is by dialing 911 from any phone (even if the fire is out).
            Alternatively, activation of any fire alarm system device (smoke/heat detector, sprinkler head) will automatically
            summon emergency responders.</p>

        <h2>Evacuation Procedures</h2>

        <p>All residents must evacuate Island Condos during both fire drills and fire emergencies. Failure to do so is a
            violation of fire code and will result in:</p>

        <p>First infraction: written warning and automatic probation</p>
        <p>Second infraction: removal from Island Condos and forfeiture of security deposit</p>

        <p>Exit routes are posted in every building. Familiarize yourself with them so you are prepared in case of
            emergency.</p>

        <h2>Title IX</h2>

        <p>Island Condos believes that every student has the right to participate in community life without sex or gender
            discrimination; student housing policies on Title IX are in strict alignment with those of Bay Atlantic University
            and Mentora College.</p>

        <p>Bay Atlantic University’s policy states in part:</p>

        <p>According to Title IX of the Education Amendments of 1972: “No person in the United States shall, on the basis of
            sex, be excluded from participation in, be denied the benefits of, or be subjected to discrimination under any
            education program or activity…”</p>

        <p>Bay Atlantic University does not and will not tolerate sexual harassment of students, faculty, and/or staff.
            Sexual harassment is a form of sex discrimination that is illegal under Title IX of the Education Amendments of
            1972. Sexual harassment is deemed to have occurred when:</p>

        <ul>
            <li>Submission to harassment is either explicitly or implicitly a term or condition of an individual’s employment
                or academic performance; or
            </li>
            <li>Submission to or rejection of such conduct by an individual is used as the basis for academic or employment
                decisions, including, but not limited to promotion, transfer, selection for training or performance evaluation,
                or used as the basis for academic evaluation; or
            </li>
            <li>The conduct has the purpose or effect of unreasonably interfering with an affected person’s work performance
                or academic performance or participation in educational pursuits; or
            </li>
            <li>The conduct has the purpose or effect of creating an intimidating, hostile, or offensive environment.</li>
        </ul>

        <p>BAU offers training to students, faculty, and staff on Title IX at the beginning of each semester. The Title IX
            officer is Shawneen Jones, the HR Director, on the 2<sup>nd</sup> floor and at <a href="mailto:hr@bau.edu"><b>hr@bau.edu</b></a>.
        </p>

        <h3><b>Retaliation</b></h3>

        <p>According to Title IX of the Education Amendments, it is illegal to commit a retaliatory act (e.g., giving
            failing grades or preventing certain individuals from participation in programs because of an individual’s
            identity, because the student had filed a complaint).</p>

        <h3><b>Sexual Harassment Policy</b></h3>

        <p>Bay Atlantic University does not and will not tolerate sexual harassment of students, faculty, and/or staff.
            Sexual harassment is a form of sex discrimination that is illegal under Title IX of the Education Amendments of
            1972. Sexual harassment is deemed to have occurred when:</p>

        <ol class="numbered-list">
            <li>Submission to harassment is either explicitly or implicitly a term or condition of an individual’s employment
                or academic performance; or
            </li>
            <li>Submission to or rejection of such conduct by an individual is used as the basis for academic or employment
                decisions, including, but not limited to promotion, transfer, selection for training or performance evaluation,
                or used as the basis for academic evaluation; or
            </li>
            <li>The conduct has the purpose or effect of unreasonably interfering with an affected person’s work performance
                or academic performance or participation in educational pursuits; or
            </li>
            <li>The conduct has the purpose or effect of creating an intimidating, hostile, or offensive academic
                environment.
            </li>
        </ol>

        <p><a href="https://bau.edu/wp-content/uploads/2022/09/Title-IX-Policy.pdf"><b>Title IX Policy</b></a></p>
        <p><a href="https://bau.edu/wp-content/uploads/2022/09/title-ix-initial-complaint-form.pdf"><b>Title IX Initial
            Complaint Form</b></a></p>
        <p><a
            href="https://bau.edu/wp-content/uploads/2022/09/Anti-Discrimination-and-Harassment-Policy-Related-Title-IX-.pdf"><b>Anti
            Discrimination and Harassment Policy</b></a></p>


        <b-col>
            <ValidationObserver ref="formModalValidate">
                <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                    <div class="mr-3">
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="form.status"
                            name="checkbox-1"
                            value="accepted"
                            :unchecked-value="null"
                        >
                            I have read, understood, and accept
                        </b-form-checkbox>
                        <b-form-invalid-feedback v-if="errors[0]"
                                                 v-html="errors[0]">

                        </b-form-invalid-feedback>
                    </div>
                </ValidationProvider>
                <div class="d-flex justify-content-center  align-center mt-3 mb-3">
                    <b-button @click="next" variant="primary" class="mr-3">{{
                            $t('next')
                        }}
                    </b-button>
                </div>
            </ValidationObserver>
        </b-col>


    </b-container>
</template>
<script>
export default {

    data() {
        return {
            form: {
                status:null,
            }
        }
    },
    methods:{
        async next() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.$emit('next', 1)
            }

        },
    }
}
</script>
<style>
@import ".././Style/Style.css";
</style>
