<template>
    <b-container>

        <h2>Right of Entry</h2>

        <p>Student Housing reserves the right to enter any room, at any time, with or without prior notification, for
            reasons including but not limited to:</p>

        <ol>
            <li>Health, safety, sanitation, maintenance, and inspection or to show vacancies.</li>
            <li>To stop noises from speakers, mobile devices, alarm clocks, etc., that are presenting a disruption to the
                community when occupants are not available to take action themselves.
            </li>
            <li>To make alterations, improvements, or repairs.</li>
            <li>In case of emergency (e.g., situations that would result in harm to persons or damage to property).</li>
            <li>General inspections.</li>
            <li>Suspected abandonment of room.</li>
            <li>Pursuant to court order.</li>
        </ol>

        <p>If an unscheduled entry is necessary (except in cases of emergency), two authorized members of staff will be
            present at all times and will leave you notice of:</p>

        <ol>
            <li>Date and time of entry.</li>
            <li>Reason for entry.</li>
            <li>Action taken (if applicable).</li>
        </ol>

        <h2>Items Provided by Island Condos</h2>

        <p><i>Personal Rooms</i></p>

        <p>All rooms are fully furnished with beds and either mobile or built-in storage. Additionally, your room will be
            supplied with:</p>

        <ul>
            <li>Linens – fitted and top sheet, 1 pillow and pillowcase, and 1 blanket (per resident)</li>
            <li>Full-size mirror</li>
            <li>Dressers</li>
        </ul>

        <p><i>Common Areas</i></p>

        <ul>
            <li>Kitchenware</li>
            <li>All major kitchen appliances including refrigerator, stove/oven, and microwave</li>
            <li>Washer/dryer</li>
        </ul>

        <p>In the event of damage to or loss of any items provided by Island Condos, the cost of the item’s replacement will
            be deducted from the security deposit of the unit residents. Should the cost of replacement exceed the amount of
            the security deposit, the remaining debt will be billed.</p>

        <h2>Things Not Supplied by Island Condos</h2>

        <p>Personal hygiene products such as soap, shampoo, conditioner, toothpaste, toothbrushes, towels, etc., are the
            responsibility of the tenant. Additionally, common area cleaning products like laundry detergent, dishwasher and
            dishwashing liquid, soap, paper towels, and garbage bags <b>are not provided.</b></p>

        <h2>Keys and Fobs</h2>

        <p>Each resident will be given a room key and a fob to open the outside gate. It is the responsibility of every
            resident to keep their keys safe and not share them with outside parties. Both your key and your fob must be
            returned at check-out. If you do not return either or both, you will be charged for lost keys and fobs.</p>

        <h2>Fees for Lost Keys and Fobs</h2>

        <p>If you misplace your key and/or fob, please inform student housing staff, and they will assist you. You will be
            asked to show a government-issued ID (passport or US driver license) to gain access to your room. Unfortunately,
            we cannot grant access to anyone who cannot prove their identity. Please take a photo of the picture page of your
            passport and/or your license and always keep it in your gallery.</p>

        <ul>
            <li>Lost key: $75</li>
            <li>Lost fob: $75</li>
            <li>Lost key and fob: $150</li>
        </ul>

        <h2>Luggage Storage</h2>

        <p>Tenants are advised to use the luggage storage which is available on the rooftop of most units. Storing large
            items such as luggage in rooms is a violation of DC Fire Code and is, consequently, strictly prohibited.</p>

        <p>If rooftop storage is not available, you will be directed to a storage location in your building.</p>

        <h2>Mail</h2>

        <p>Mail will be received and is available for pick up at the Island Condo security office between 10:00 AM and 8:00
            PM. Mail and parcels will be held for 30 days, after which, it will be marked unclaimed and returned to the
            sender. Be sure to check the mailboxes located in front of the security office regularly.</p>

        <p>Please note: Island Condos <b><u>is not</u></b> responsible for lost, stolen, or undelivered mail.</p>

        <h2>Internet Access</h2>

        <p>Each unit has its own network, so please find yours in the network list on your device. The password for all
            units is Isl@nd2016.</p>

        <h2>Pets</h2>

        <p>All pets are strictly prohibited in our buildings except for documented service animals.</p>

        <h2>Service Animals</h2>

        <p>A service animal is defined in Title II Section 35.104 under the Americans with Disabilities Act (ADA) as a dog
            that is trained to do work or perform tasks for the benefit of an individual with a disability including a
            physical, sensory, psychiatric, intellectual, or other mental disability.</p>

        <p>Examples of such work or tasks include but are not limited to:</p>

        <ul>
            <li>Guiding people who are blind or have low vision.</li>
            <li>Alerting deaf persons to the presence of people or sounds.</li>
            <li>Wheelchair assistance.</li>
            <li>Alerting an individual of a seizure, change in blood sugar, or allergen.</li>
            <li>Reminding a person with mental illness to take prescribed medications.</li>
            <li>Calming a person with PTSD during an anxiety attack.</li>
        </ul>

        <p>If you will bring your service animal to Island Condos, please complete the <a
            href="https://docs.google.com/document/d/1-G19ALsGUQe0MaU3tPQG42ccwZ_BnX2cCmr0gD9SkxA/edit?usp=sharing"><b>Service
            Animal Housing Request Form</b></a></p>
        <b-col>
            <ValidationObserver ref="formModalValidate">
                <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                    <div class="mr-3">
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="form.status"
                            name="checkbox-1"
                            value="accepted"
                            :unchecked-value="null"
                        >
                            I have read, understood, and accept
                        </b-form-checkbox>
                        <b-form-invalid-feedback v-if="errors[0]"
                                                 v-html="errors[0]">

                        </b-form-invalid-feedback>
                    </div>
                </ValidationProvider>
                <div class="d-flex justify-content-center  align-center mt-3 mb-3">
                    <b-button @click="next" variant="primary" class="mr-3">{{
                            $t('next')
                        }}
                    </b-button>
                </div>
            </ValidationObserver>
        </b-col>


    </b-container>
</template>
<script>
export default {

    data() {
        return {
            form: {
                status:null,
            }
        }
    },
    methods:{
        async next() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.$emit('next', 1)
            }

        },
    }
}
</script>
<style>
@import ".././Style/Style.css";
</style>
