<template>
    <div>
        <div v-if="!isTenant">
            <app-layout>
                <template v-slot:header>
                    <Header :title="$t('housing_request_list')"
                            @filter-div-status="datatable.filterStatus = $event">
                        <template v-slot:columns>
                            <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                                <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                                 :unchecked-value="true" v-model="column.hidden"
                                                 v-if="column.field != 'buttons'">
                                    {{ column.label }}
                                </b-form-checkbox>
                            </div>
                        </template>
                    </Header>
                </template>
                <template v-slot:header-mobile>
                    <HeaderMobile :title="$t('housing_request_list')"
                                  @filter-div-status="datatable.filterStatus = $event">
                        <template v-slot:columns>
                            <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                                <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                                 :unchecked-value="true" v-model="column.hidden"
                                                 v-if="column.field != 'buttons'">
                                    {{ column.label }}
                                </b-form-checkbox>
                            </div>
                        </template>
                    </HeaderMobile>
                </template>
                <div class="p-3">
                    <h5>RESULT</h5>
                    <div v-if="!datatable.rows.length" class="d-flex justify-content-center align-items-center p-5">
                        <b-spinner variant="primary" label="Loading..."></b-spinner>
                        <span class="ml-2">Loading...</span>
                    </div>
                    <div v-else>
                        <b-row v-for="(reservation, index) in datatable.rows" :key="index" class="border-bottom py-3">
                            <b-col
                                v-for="visibleKey in visibleKeys"
                                :key="visibleKey"
                                cols="12"
                                md="2"
                                class="d-flex align-items-center mb-2 mb-md-0"
                            >
                                <i :class="getIconClass(visibleKey, reservation[visibleKey])" class="remix-icon mr-2"></i>
                                <span>{{ reservation[visibleKey] }}</span>
                            </b-col>

                            <b-col cols="12" md="2" class="d-flex align-items-center justify-content-md-end">
                                <b-button
                                    v-if="requestButtonRules(reservation)"
                                    variant="primary"
                                    class="d-flex align-items-center"
                                    @click="requestFormShow(reservation)"
                                >
                                    <i class="ri-add-line remix-icon mr-2"></i>
                                    Add Request
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </app-layout>
        </div>
        <div v-else>
            <div class="m-3 d-flex flex-column">
                <TenantHeader/>
                <div class="p-3">
                    <h5>RESULT</h5>
                    <div v-if="!datatable.rows.length" class="d-flex justify-content-center align-items-center p-5">
                        <b-spinner variant="primary" label="Loading..."></b-spinner>
                        <span class="ml-2">Loading...</span>
                    </div>

                    <div v-else>
                        <b-row v-for="(reservation, index) in datatable.rows" :key="index" class="border-bottom py-3">
                            <b-col
                                v-for="visibleKey in visibleKeys"
                                :key="visibleKey"
                                cols="12"
                                md="2"
                                class="d-flex align-items-center mb-2 mb-md-0"
                            >
                                <i :class="getIconClass(visibleKey, reservation[visibleKey])" class="remix-icon mr-2"></i>
                                <span>{{ reservation[visibleKey] }}</span>
                            </b-col>

                            <b-col cols="12" md="2" class="d-flex align-items-center justify-content-md-end">
                                <b-button
                                    v-if="requestButtonRules(reservation)"
                                    variant="primary"
                                    class="d-flex align-items-center"
                                    @click="requestFormShow(reservation)"
                                >
                                    <i class="ri-add-line remix-icon mr-2"></i>
                                    Add Request
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>

        </div>
        <CommonModal ref="requestFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
            <template v-slot:CommonModalTitle>
                {{ $t('request_form').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <request-form :formId="formId"  :reservationData=formData @requestFormSuccess="requestFormSuccess"
                              v-if="formProcess == 'request'"/>
            </template>
        </CommonModal>
    </div>

</template>

<script>
import TenantHeader from "@/modules/myReservationList/pages/TeanatHeader.vue";
import RequestForm from "@/modules/myReservationList/pages/RequestForm.vue";
import qs from "qs";
import HousingReservationsListService from "@/services/HousingReservationsListService";
import LocalStorage from "@/plugins/LocalStorage";
import moment from 'moment';


export default {
    components: {
        RequestForm,
        TenantHeader
    },
    metaInfo() {
        return {
            title: this.$t('my_room_reservation')
        }
    },
    data() {
        return {
            visibleKeys: [
                "date_range",
                "building_information",
                "payment_information",
                "status_name",
            ],
            tenantUser:{},
            datatable: {
                isLoading: false,
                rows: [],
                total: 0,
                showTable: true,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },
            formLoading: false,
            formId: null,
            formProcess: null,
        }
    },
    created() {
        this.getToken()
    },
    mounted() {
        if (this.isTenant) {
            this.tenantUserInformation()
        }
    },
    computed: {
        isTenant() {
            return !!localStorage.getItem("tenant_user")
        },
        isStudent() {
          return this.$store.getters["auth/isStudent"];
        },

    },
    methods: {
        requestButtonRules(row){
            const formatedDate = moment(new Date()).format('YYYY-MM-DD');
            const checkOutDate = moment(row.check_out_date, 'MM/DD/YYYY').format('YYYY-MM-DD');
            return (checkOutDate >= formatedDate) && row.status === 'paid';
        },
        getIconClass(field,value) {
            let icons = {
                building_information: "ri-hotel-line",
                payment_information: "ri-money-dollar-box-line",
                date_range:'ri-calendar-check-line',
            };
            if (value === 'Agreement Form Signed') {
                icons.status_name='ri-file-edit-line'
            } else if (value === 'Approved') {
                icons.status_name='ri-checkbox-circle-line'
            } else if (value === 'Declined') {
                icons.status_name='ri-close-circle-line'
            } else if (value === 'Deposit Paid') {
                icons.status_name='ri-bank-card-line'
            } else if (value === 'Paid') {
               icons.status_name='ri-check-double-line'
            } else if (value === 'Pending') {
                icons.status_name='ri-time-line'
            }
            return icons[field] || "ri-information-line";
        },
        async getToken(){
            if (!this.isTenant) {
                const token = LocalStorage.get('token')
                if (!token){
                    await this.$store.dispatch('roomReservationSystem/setReservationToken');
                    const newToken=JSON.parse(localStorage.getItem('token'));
                    this.token = newToken;
                    await this.getRows(newToken)
                }else{
                    this.token = token;
                    await this.getRows(token)
                }
            }else {
                this.getRows()
            }
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type === 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        async tenantUserInformation() {
            const tenantUsers =localStorage.getItem('tenant_user')?JSON.parse(localStorage.getItem('tenant_user')):{}
            this.tenantUser=tenantUsers

        },
        async getRows() {
            const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return HousingReservationsListService.getAll(config,this.isTenant)
                .then((response) => {
                    this.datatable.rows = response.data.data.map((item)=>{
                        return {
                            ...item,
                            date_range:item.check_in_date + ' - ' +item.check_out_date,
                            building_information:item.building_name+' / '  +item.room_name +' : '+item.bed_name+' - '+item.bed_type_name ,
                            payment_information:this.currencyFormat(item.total_amount,0)+' - '+this.currencyFormat(item.total_paid_amount,0)+' per month '+`(Balance : ${this.currencyFormat(item.balance,0)})`,
                        }
                    });
                    this.datatable.total = response.data.pagination.total;
                })
                .catch((e) => {
                  if (e.response.status===401){
                    this.$router.push('/housing/tenant/login');
                    localStorage.getItem('tenant_user')?? localStorage.removeItem('tenant_user');
                    localStorage.getItem('token')?? localStorage.removeItem('tenant_user');
                  }
                    this.showErrors(e);
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        requestFormShow(row) {
            this.formId = row.id
            this.formData=row
            this.formProcess = 'request'
            this.$refs.requestFormModal.$refs.commonModal.show()
        },
        requestFormSuccess() {
            this.$refs.requestFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
    }

}


</script>
<style scoped>
.remix-icon {
    font-size: 20px;
    color: #007bff;
}
</style>
